// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-pandapass-js": () => import("./../../../src/pages/pandapass.js" /* webpackChunkName: "component---src-pages-pandapass-js" */),
  "component---src-pages-pumpkinswap-js": () => import("./../../../src/pages/pumpkinswap.js" /* webpackChunkName: "component---src-pages-pumpkinswap-js" */),
  "component---src-pages-startesr-js": () => import("./../../../src/pages/startesr.js" /* webpackChunkName: "component---src-pages-startesr-js" */),
  "component---src-pages-startgame-2-js": () => import("./../../../src/pages/startgame2.js" /* webpackChunkName: "component---src-pages-startgame-2-js" */),
  "component---src-pages-startgame-js": () => import("./../../../src/pages/startgame.js" /* webpackChunkName: "component---src-pages-startgame-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

